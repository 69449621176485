




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { ApiResponse } from '@/components/types';
import { FETCH_FILE_BY_ID } from '@/modules/fileManagement/store';
import { File, MimeType, SearchFile, Type } from '@/modules/fileManagement/types';
import fileDownload from 'js-file-download';
import PdfView from '@/modules/fileManagement/components/PdfView.vue';
import ImageView from '@/modules/fileManagement/components/ImageView.vue';

const FileManagement = namespace('fileManagement');

@Component({
  components: {
    PdfView,
    ImageView,
    ErrorAlert
  }
})
export default class ViewFileDialog extends Vue {
  @Prop({ type: Object }) file!: File | SearchFile;

  @FileManagement.Action(FETCH_FILE_BY_ID) fetchFile!: ({ file, type }: { file: string, type: Type }) => Promise<ApiResponse<Blob>>;

  error: any = null;
  dialog: boolean = false;

  loading: boolean = false;
  fileBlob: Blob | null = null;
  fileUrl: string = '';
  view: string = '';

  views = {
    [MimeType.PDF as string]: 'pdf-view',
    [MimeType.PNG as string]: 'image-view',
    [MimeType.JPG as string]: 'image-view',
  };

  download() {
    if (this.fileBlob) {
      fileDownload(this.fileBlob, this.file.filename);
    }
  }

  print() {
    if (this.fileUrl) {
      const iframe =  document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.src = this.fileUrl;
      iframe.onload = function() {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
      this.fileBlob = null;
      this.view = '';

      if (this.fileUrl) {
        URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = '';
      }
    }, 200);

    this.$emit('update:file', null);
  }

  @Watch('file', { immediate: true })
  watchFolder(file: File) {
    if (!file) return;

    this.loading = true;
    this.view = this.views[file.mimeType];

    this.fetchFile({ file: file.id, type: file.type }).then(({ content, error }) => {
      if (content) {
        this.fileBlob = content;
        this.fileUrl = URL.createObjectURL(content);
      }

      if (error) {
        this.error = error;
      }

      this.loading = false;
    });

    this.dialog = true;
  }
}






































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import FileDownload from '@/modules/fileManagement/components/FileDownload.vue';
import AppTable from '@/components/AppTable.vue';
import RenameFileDialog from '../components/RenameFileDialog.vue';
import RemoveFileDialog from '../components/RemoveFileDialog.vue';
import ViewFileDialog from '@/modules/fileManagement/components/ViewFileDialog.vue';
import { MimeType, SearchFile } from '../types';

@Component({
  components: {
    ViewFileDialog,
    RemoveFileDialog,
    RenameFileDialog,
    AppTable,
    FileDownload,
  }
})
export default class Search extends AuthorizationMixin {
  @Prop({ type: Array, default: () => [] }) items!: SearchFile[];
  @Prop({ type: Function, required: true }) searchFiles!: () => void;

  renameFile: SearchFile | null = null;
  deleteFile: SearchFile | null = null;
  viewFile: SearchFile | null = null;

  supportedViews: MimeType[] = [
    MimeType.JPG,
    MimeType.PDF,
    MimeType.PNG
  ];

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.filename'), value: 'filename' },
      { align: 'left', text: this.$i18n.t('common.folder'), value: 'folder' },
      { align: 'left', text: this.$i18n.t('common.conservatee'), value: 'conservatee' },
      ...(this.isAdmin() ? [{ align: 'left', text: this.$i18n.t('common.conservator'), value: 'owner' }] : []),
      { align: 'right', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'right', text: this.$i18n.t('common.actions'), value: null, sortable: false },
    ];
  }
}

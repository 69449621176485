






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { FETCH_FILE_BY_ID } from '@/modules/fileManagement/store';
import { File, Type } from '@/modules/fileManagement/types';
import fileDownload from 'js-file-download';

const FileManagement = namespace('fileManagement');

@Component({})
export default class FileDownload extends Vue {
  @Prop({ type: Object, required: true }) file!: File;

  @FileManagement.Action(FETCH_FILE_BY_ID) fetchFile!: ({ file, type }: { file: string, type: Type }) => Promise<ApiResponse<Blob>>;

  error: any = null;
  loading: boolean = false;
  fileBlob: Blob | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
      this.fileBlob = null;
    }, 200);

    this.$emit('update:file', null);
  }

  download() {
    this.loading = true;

    if (this.fileBlob) {
      fileDownload(this.fileBlob, this.file.filename);
      this.loading = false;

      return;
    }

    this.fetchFile({ file: this.file.id, type: this.file.type }).then(({ content, error }) => {
      if (content) {
        this.fileBlob = content;

        fileDownload(content, this.file.filename);
      }

      if (error) {
        this.error = error;
      }

      this.loading = false;
    });
  }
}

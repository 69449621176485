







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { File, RenameFile, SearchFile } from '../types';
import { RENAME_FILE } from '@/modules/fileManagement/store';
import { ApiResponse } from '@/components/types';

const FileManagement = namespace('fileManagement');

@Component({})
export default class RenameFileDialog extends Vue {
  @Prop({ type: Object }) value!: File | SearchFile | null;

  @FileManagement.Action(RENAME_FILE) rename!: (command: RenameFile) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  file: RenameFile = {
    file_id: '',
    name: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchFile(file: File) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.file = {
      file_id: '',
      name: ''
    };

    this.error = null;
    this.$emit('input', null);
    // @ts-ignore
    this.$refs.form.reset();
  }

  init() {
    if (!this.value) return;

    this.file = {
      file_id: this.value.id,
      name: this.value.name
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.rename(this.file);
  }
}
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DeleteFile, File } from '../types';
import { DELETE_FILE } from '@/modules/fileManagement/store';
import { ApiResponse } from '@/components/types';

const FileManagement = namespace('fileManagement');

@Component({})
export default class RemoveFileDialog extends Vue {
  @Prop({ type: Object }) value!: File | null;

  @FileManagement.Action(DELETE_FILE) remove!: (command: DeleteFile) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  file: DeleteFile = {
    file_id: '',
    filename: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.clear();
    }, 200);
  }

  @Watch('value')
  watchFile(file: File) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear() {
    this.file = {
      file_id: '',
      filename: ''
    };

    this.error = null;
    this.$emit('input', null);
  }

  init() {
    if (!this.value) return;

    this.file = {
      file_id: this.value.id,
      filename: this.value.filename
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.remove(this.file);
  }
}
